<template src="./FirstAdmissionB.html">
</template>

<script>
import firstAdmissionService from '@/services/FirstAdmission';

export default {
  name: 'first-admission-b',
  data () {
    return {
      securityImages: null,
      securityWord: null,
      selectedWord: null,
      selectedImage: null,
    }
  },
  methods: {
    redirectTo(route) {
      this.$router.push({path: `${route}`})
    },
    postSecurityImageAndWord() {
      const securityData = {
        image: this.selectedImage,
        word: this.selectedWord
      }
      firstAdmissionService.firstLogin(securityData)
        .then(response => {
          sessionStorage.setItem('atId', response['@id']) // TODO meter el @id a vuex
          this.$router.push({path: '/nuevousuario3'})
        }).catch((e)=>{
          console.error('error',e);
      })
    }
  },
  created () {
    firstAdmissionService.getImageAndWordToConfirmation()
      .then(response => {
        this.securityImages = response.securityImages;
        this.securityWord = response.securityWord;
      }).catch((e)=>{
        console.error('error', e);
    })
  },
  computed: {
    disableButton() {
      return this.selectedImage && this.selectedWord;
    }
  }
}
</script>

<style lang="scss" scoped src="./FirstAdmissionB.scss">
</style>
